




























































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Api } from "@/models/class/api.class";
import { MAX_FILE_SIZE } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { WAREHOUSE_STATE } from "@/models/enums/warehouse-transfer.enum";
import {
  RequestDeleteFile,
  ResponseUploadData,
} from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataInventory,
  DataWarehouseLocation,
  ResponseListOfInventory,
} from "@/models/interface/logistic.interface";
import { DataListProduct } from "@/models/interface/product.interface";
import { ResponseListOfMasterUom } from "@/models/interface/settings.interface";
import {
  DataListWarehouseTransfer,
  DetailWarehouseTransfer,
  RequestWarehouseTransfer,
} from "@/models/interface/warehouse.interface";
import { assetsServices } from "@/services/assets.service";
import { logisticServices } from "@/services/logistic.service";
import { settingsServices } from "@/services/settings.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import Decimal from "decimal.js-light";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";

interface IDataWarehouse {
  id: string | null;
  key: number;
  productId: string;
  qtyTotal: number;
  uomId: string;
  qtyReceive?: number;
  qtyOutstanding?: number | string;
  disabledInput?: boolean;
  disabledSelect?: boolean;
  optionCustom?: any;
}

interface IDataListLocation {
  id: string;
  name: string;
}

interface IDataProduct {
  id: string;
  name: string;
  uomId?: string;
}

interface IDataProductUom {
  id: string;
  name: string;
  description?: string;
  unit?: string;
  uomId: string;
}

export default Vue.extend({
  name: "WarehouseTransfer",
  mixins: [MNotificationVue],
  data() {
    this.getListLocation = debounceProcess(this.getListLocation, 200);
    this.getListOfBaseUnit = debounceProcess(this.getListOfBaseUnit, 200);
    this.getListOfProduct = debounceProcess(this.getListOfProduct, 200);
    this.handleSearchSelectTable = debounceProcess(
      this.handleSearchSelectTable,
      400
    );
    return {
      attachmentInfo:
        this.$t("lbl_upload_info_3").toString() +
        " " +
        this.$t("lbl_upload_info_1", {
          size: new Decimal(MAX_FILE_SIZE).dividedBy(1000000),
        }).toString(),
      urlAttachment: "",
      headers: {
        authorization: "Bearer " + this.$store.state.access_token,
      },
      Api: Api,
      maxFileSize: MAX_FILE_SIZE,
      DEFAULT_DATE_FORMAT,
      form: this.$form.createForm(this, { name: "warehousetransfer" }),
      firstMount: false as boolean,
      key: "details",
      columnsTable: [
        {
          title: this.$t("lbl_part"),
          dataIndex: "productId",
          key: "productId",
          sorter: false,
          width: "33%",
          scopedSlots: { customRender: "productId" },
          responsiveColInput: [
            {
              name: "qtyTotal",
              placeholder: "0",
              disabled: false,
            },
          ],
          responsiveColSelect: [
            {
              name: "uomId",
              placeholder: "Unit",
              value: "id",
              options: [] as IDataProductUom[],
              style: "width: 100%",
            },
            {
              name: "productId",
              placeholder: "Product",
              value: "id",
              options: [] as IDataProduct[],
              style: "width: 100%",
              disabled: false,
              filterOption: "remote",
            },
          ],
        },
        {
          title: this.$t("lbl_total_qty"),
          dataIndex: "qtyTotal",
          key: "qtyTotal",
          sorter: false,
          width: "33%",
          scopedSlots: { customRender: "qtyTotal" },
        },
        {
          title: this.$t("lbl_uom"),
          dataIndex: "uomId",
          key: "uomId",
          sorter: false,
          width: "33%",
          scopedSlots: { customRender: "uomId" },
        },
      ],
      dataList: [] as IDataWarehouse[],
      formRules: {
        no: {
          label: "lbl_document_number",
          name: "No",
          placeholder: "lbl_document_number",
          decorator: ["no", {}],
        },
        from: {
          label: "lbl_from",
          name: "From",
          placeholder: "lbl_from",
          decorator: [
            "from",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        to: {
          label: "lbl_to",
          name: "To",
          placeholder: "lbl_to",
          decorator: [
            "to",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        date: {
          label: "lbl_date",
          name: "Date",
          placeholder: "lbl_date",
          decorator: ["date"],
        },
        receiveDate: {
          label: "lbl_receive_date",
          name: "ReceiveDate",
          placeholder: "lbl_receive_date",
          decorator: ["receiveDate", {}],
        },
        note: {
          label: "lbl_note",
          name: "Note",
          placeholder: "lbl_note",
          decorator: ["note"],
        },
        attachment: {
          label: "lbl_attachment",
          name: "file",
          placeholder: "lbl_upload",
          decorator: [
            "attachment",
            {
              valuePropName: "fileList",
              getValueFromEvent: this.normFile,
              rules: [],
            },
          ],
        },
      },
      selectedRowKeys: [] as number[],
      loadingLocation: false as boolean,
      dataListLocation: [] as DataWarehouseLocation[],
      dataListInventory: [] as DataInventory[],
      optDataListLocation: [] as IDataListLocation[],
      documentId: "" as string,
      resFile: {} as ResponseUploadData,
      detailTransfer: {
        state: WAREHOUSE_STATE.NEW,
      } as DetailWarehouseTransfer,
      dataListProduct: {} as DataListProduct,
      loading: {
        attachment: false,
        submit: false as boolean,
        draft: false as boolean,
        print: false,
      },
      locationId: {
        from: "" as string,
        to: "" as string,
      },
      deletedLineIds: [] as string[],
    };
  },
  watch: {
    dataList: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstMount) {
          if (
            (this.dataList.length > 0 && this.isCancelled) ||
            this.isReceived
          ) {
            this.dataList = newValue.map(dataMap => {
              dataMap.disabledInput = true;
              dataMap.disabledSelect = true;
              return dataMap;
            });
            this.firstMount = true;
          }
        }
      },
    },
    detailTransfer: {
      immediate: true,
      deep: true,
      handler() {
        this.assignOptionProduct();
      },
    },
    dataListInventory: {
      immediate: true,
      deep: true,
      handler() {
        this.assignOptionProduct();
      },
    },
  },
  computed: {
    isSubmitted(): boolean {
      return this.detailTransfer.state === WAREHOUSE_STATE.SUBMITTED;
    },
    isReceived(): boolean {
      return this.detailTransfer.state === WAREHOUSE_STATE.RECEIVED;
    },
    submitText(): string {
      return this.detailTransfer.state === WAREHOUSE_STATE.DRAFT
        ? "lbl_submit"
        : this.detailTransfer.state === WAREHOUSE_STATE.SUBMITTED
        ? "lbl_received"
        : "lbl_create";
    },
    isDraft(): boolean {
      return this.detailTransfer.state === WAREHOUSE_STATE.DRAFT;
    },
    isNew(): boolean {
      return this.detailTransfer.state === WAREHOUSE_STATE.NEW;
    },
    isCancelled(): boolean {
      return this.detailTransfer.state === WAREHOUSE_STATE.CANCELLED;
    },
    locationTo(): string {
      return this.form.getFieldValue("to");
    },
    locationFrom(): string {
      return this.form.getFieldValue("from");
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "warehousetransfer" });
  },
  created() {
    this.getListLocation("");
    this.getListOfBaseUnit();
    this.getListOfProduct();
  },
  mounted() {
    this.initForm();
    if (this.$route.query.id) {
      this.documentId = this.$route.query.id as string;
      this.getDetail(this.documentId);
    }
  },
  methods: {
    moment,
    normFile(e) {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },
    deleteAttachment(fileName: string): void {
      this.loading.attachment = true;
      const payload: RequestDeleteFile = {
        bucketName: "logistic",
        objectName: fileName,
      };
      assetsServices
        .deleteUploadedFile(payload)
        .then(() => {
          this.showNotifSuccess("notif_delete_success");
          this.urlAttachment = "";
        })
        .finally(() => (this.loading.attachment = false));
    },
    handleChangeAttachment(info) {
      if (info.file.status === "uploading") {
        this.loading.attachment = true;
      }
      if (info.file.status === "done") {
        this.urlAttachment = info.file.response.url;
        this.showNotifSuccess("notif_file_upload_successfully", {
          filename: info.file.name,
        });
        this.loading.attachment = false;
      } else if (info.file.status === "error") {
        this.loading.attachment = false;
        this.showNotifError("notif_file_upload_failed", {
          filename: info.file.name,
        });
      }
    },
    handlePrint(): void {
      this.loading.print = true;
      logisticServices
        .getDownloadWarehouseTransferForm(this.documentId)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response]));
          printJs(url);
        })
        .finally(() => (this.loading.print = false));
    },
    getListOfBaseUnit(): void {
      let params: any;
      params = {
        baseUnit: true,
      } as RequestQueryParamsModel;
      settingsServices
        .listOfBaseUnit(params)
        .then((res: ResponseListOfMasterUom | any) => {
          res.data.map((dataObject, index) => {
            dataObject.key = index;
            dataObject.name = dataObject.unit;
          });
          const findColumn = this.columnsTable.find(
            column => column.responsiveColSelect
          )?.responsiveColSelect;
          if (findColumn) {
            findColumn[0].options = res.data;
          }
        });
    },
    async submitForm(): Promise<void> {
      const form = this.form as WrappedFormUtils;
      form.validateFields(async err => {
        if (err && err.length) {
          const messages: string[] = [];
          for (const [key] of Object.entries(err)) {
            messages.push(key);
          }
          this.showNotifError("notif_field_mandatory_data", {
            data: messages.join(", "),
          });
        } else {
          this.handleSubmit();
        }
      });
    },
    async handleSubmit(): Promise<void> {
      try {
        const tableValue = this.dataList;
        if (tableValue.length === 0) {
          return;
        }
        const indx = this.dataList.findIndex(x => !x.productId || !x.qtyTotal);
        if (indx !== -1) {
          return;
        }
        if (this.documentId) {
          // update warehouse transfer state
          if (this.detailTransfer.state === WAREHOUSE_STATE.DRAFT) {
            this.handleSubmitWarehouseTransfer();
          } else if (this.detailTransfer.state === WAREHOUSE_STATE.SUBMITTED) {
            this.receiveTransfer();
          }
        } else {
          this.createWarehouseTransfer();
        }
      } catch (error) {
        this.showNotifError("notif_process_fail");
      }
    },
    handleSubmitWarehouseTransfer(): void {
      const form = this.form as WrappedFormUtils;
      const tableValue = this.dataList;
      const formValue = form.getFieldsValue();
      const payload: RequestWarehouseTransfer = {
        attachment: this.resFile.objectName,
        date: this.moment(formValue.date).utc().format() || "",
        destinationLocationId: formValue.to || "",
        notes: formValue.note || "",
        sourceLocationId: formValue.from || "",
        deletedLineIds: this.deletedLineIds,
        transferLines: [],
      };

      tableValue.forEach(x => {
        payload.transferLines.push({
          id: x.id,
          productId: x.productId,
          receivedQty: x.qtyReceive,
          totalQty: x.qtyTotal,
          uomId: x.uomId,
        });
      });

      if (this.detailTransfer.transferLines.length) {
        this.detailTransfer.transferLines.forEach((item, index) => {
          payload.transferLines[index].secureId = item.id;
          payload.transferLines[index].id = item.id;
        });
      }

      this.submitTransfer(this.documentId, payload);
    },
    getListLocation(valueSearch: string) {
      let params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };

      if (valueSearch) {
        params.search = `warehouse.branchWarehouse.name~*${valueSearch}*_OR_warehouse.branchWarehouse.code~*${valueSearch}*_OR_warehouse.branchWarehouse.address~*${valueSearch}*_OR_warehouse.name~*${valueSearch}*_OR_name~*${valueSearch}*`;
      }
      this.loadingLocation = true;
      logisticServices
        .listWarehouseLocation(params, "")
        .then(data => {
          this.dataListLocation = data.data;
          this.optDataListLocation = data.data.map(e => {
            return {
              id: e.id,
              name: `${e.warehouse.branchWarehouse.name} - ${e.warehouse.name} - ${e.name}`,
            };
          });
        })
        .finally(() => (this.loadingLocation = false));
    },
    onSelectChange(value, key, col, recordOptions): void {
      this.dataList[key] = { ...this.dataList[key], [col]: value };
      this.dataList = this.dataList.slice();

      if (col === "productId" && value) {
        logisticServices.getDetailProduct(value).then(res => {
          this.dataList[key].uomId = res.uomConversions[0].uomSourceId;
          this.dataList[key].optionCustom[0].option = res.uomConversions.map(
            dataMap => {
              return {
                ...dataMap,
                name: dataMap.uomSource,
                id: dataMap.uomSourceId,
              };
            }
          );
        });
      }
    },
    handleDeleteRow(): void {
      const { dataList } = this;
      this.dataList = dataList.filter(data => {
        if (data.id && this.selectedRowKeys.includes(data.key)) {
          this.deletedLineIds = [...this.deletedLineIds, data.id];
        }
        return !this.selectedRowKeys.includes(data.key);
      });

      this.dataList.forEach((dataMap, index) => {
        dataMap.key = index;
      });

      this.selectedRowKeys = [];
    },
    showDeleteConfirmation(): void {
      if (this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: this.$t("lbl_modal_delete_title_confirm").toString(),
          content: this.$t("lbl_modal_delete_info", {
            count: this.selectedRowKeys.length,
          }).toString(),
          onOk: this.handleDeleteRow,
        });
      } else {
        this.showNotifError("lbl_modal_delete_error_description");
      }
    },
    onRowSelect(selectedRowKeys: number[]): void {
      this.selectedRowKeys = selectedRowKeys;
    },
    addRow(): void {
      this.dataList = [
        ...this.dataList,
        {
          id: null,
          key: this.dataList.length,
          productId: "",
          qtyTotal: 0,
          uomId: "",
          optionCustom: [
            {
              name: `uomId`,
              option: [],
            },
          ],
        },
      ];
    },
    beforeUpload(file) {
      const isLt5M = file.size;
      if (isLt5M >= MAX_FILE_SIZE) {
        this.showNotifError("lbl_message_file_size_exceeded", {
          fileSize: new Decimal(MAX_FILE_SIZE).dividedBy(1000000),
        });
        return false;
      }
      return true;
    },
    handleRemove(file) {
      if (!file.url) return;
      const payload: RequestDeleteFile = {
        objectName: file.url,
        bucketName: "logistic",
      };
      assetsServices
        .deleteUploadedFile(payload)
        .then(() => this.$message.success(Messages.DELETE_SUCCESS));
    },
    checkerNumberQtyDifference(count, key) {
      if (count === 0) this.dataList[key].qtyOutstanding = "0";
      else if (count < 0) this.dataList[key].qtyOutstanding = count;
      else this.dataList[key].qtyOutstanding = `+${count}`;
    },
    handleInput(value, key, objectColInput, objectColInputName, onEvt) {
      this.dataList[key][objectColInput.name] = value;
      this.dataList = this.dataList.slice();
    },
    handleTransferValidation() {
      this.formRules.receiveDate.decorator = [
        "receiveDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ];
    },
    populateTableReceive(): void {
      const column = this.columnsTable.find(
        col => col?.responsiveColInput
      )?.responsiveColInput;
      const columnSelect = this.columnsTable.find(
        col => col?.responsiveColSelect
      )?.responsiveColSelect;
      if (columnSelect) {
        columnSelect[0].disabled = true;
        columnSelect[1].disabled = true;
      }

      if (column) {
        column[0].disabled = true;
        column.push({
          name: "qtyReceive",
          placeholder: "0",
          disabled: false,
        });
        if (
          this.columnsTable.findIndex(
            z => z.key === "qtyReceive" || z.key === "qtyOutstanding"
          ) === -1
        ) {
          this.columnsTable.forEach(col => (col.width = "20%"));
          this.columnsTable.push(
            {
              title: "Qty Receive",
              dataIndex: "qtyReceive",
              key: "qtyReceive",
              sorter: false,
              width: "20%",
              scopedSlots: { customRender: "qtyReceive" },
            },
            {
              title: "Qty Outstanding",
              dataIndex: "qtyOutstanding",
              key: "qtyOutstanding",
              sorter: false,
              width: "20%",
              scopedSlots: { customRender: "qtyOutstanding" },
            }
          );
        }
      }
      this.dataList = [];
      if (this.detailTransfer.transferLines.length > 0) {
        this.detailTransfer.transferLines.forEach((item, idx) => {
          const findColumn = this.columnsTable.find(
            dataColumn => dataColumn.responsiveColSelect
          )?.responsiveColSelect;
          if (findColumn) {
            const checkUom = findColumn[0].options.some(
              dataSome => dataSome.id === item.uom.id
            );
            const checkProduct = findColumn[1].options.some(
              dataSome => dataSome.id === item.product.id
            );
            if (!checkUom) {
              findColumn[0].options.push({
                name: item.uom.unit,
                id: item.uom.id,
                unit: item.uom.unit,
                description: item.uom.description,
                uomId: item.uom.id,
              });
            }
            if (!checkProduct) {
              findColumn[1].options.push({
                id: item.product.id,
                name: item.product.name,
                uomId: item.product.uomId,
              });
            }
          }
          if (this.isSubmitted) {
            this.dataList.push({
              id: item.id,
              key: idx,
              productId: item.product.id || "",
              qtyTotal: item.qty || 0,
              uomId: item.uom.id || "",
              qtyReceive: 0,
              qtyOutstanding: item.outstandingQty || 0,
            });
          } else {
            this.dataList.push({
              id: item.id,
              key: idx,
              productId: item.product.id || "",
              qtyTotal: item.qty || 0,
              uomId: item.uom.id || "",
              qtyReceive: item.receivedQty || 0,
              qtyOutstanding: item.outstandingQty || 0,
            });
          }
        });
      }
    },
    populateTable(): void {
      if (!this.detailTransfer.transferLines.length) return;
      this.detailTransfer.transferLines.forEach((item, idx) => {
        const findColumn = this.columnsTable.find(
          dataColumn => dataColumn.responsiveColSelect
        )?.responsiveColSelect;
        if (findColumn) {
          const checkUom = findColumn[0].options.some(
            dataSome => dataSome.id === item.uom.id
          );
          const checkProduct = findColumn[1].options.some(
            dataSome => dataSome.id === item.product.id
          );
          if (!checkUom) {
            findColumn[0].options.push({
              name: item.uom.unit,
              id: item.uom.id,
              unit: item.uom.unit,
              description: item.uom.description,
              uomId: item.uom.id,
            });
          }
          if (!checkProduct) {
            findColumn[1].options.push({
              id: item.product.id,
              name: item.product.name,
              uomId: item.product.uomId,
            });
          }
        }
        this.dataList.push({
          id: item.id,
          key: idx,
          productId: item.product.id || "",
          qtyTotal: item.qty || 0,
          uomId: item.uom.id || "",
        });
      });
    },
    populateForm(): void {
      this.form.setFieldsValue({
        no: this.detailTransfer.documentNumber || "",
        from: this.detailTransfer.sourceLocationId || "",
        to: this.detailTransfer.destinationLocationId || "",
        date: this.detailTransfer.date || "",
        note: this.detailTransfer.notes || "",
      });
      this.locationId.from = this.detailTransfer.sourceLocationId;
      this.urlAttachment = this.detailTransfer.attachment;

      let sourceLocation = this.optDataListLocation.find(
        data => data.id === this.detailTransfer.sourceLocationId
      );
      let destinationLocation = this.optDataListLocation.find(
        data => data.id === this.detailTransfer.destinationLocationId
      );
      if (!sourceLocation) {
        this.optDataListLocation.push({
          id: this.detailTransfer.sourceLocationId,
          name: this.detailTransfer.sourceLocation,
        });
      }
      if (!destinationLocation) {
        this.optDataListLocation.push({
          id: this.detailTransfer.destinationLocationId,
          name: this.detailTransfer.destinationLocation,
        });
      }

      if (this.isReceived || this.isSubmitted || this.isCancelled) {
        this.populateTableReceive();
      } else if (this.isNew || this.isDraft) {
        this.populateTable();
      }
    },
    prepReq(data: {
      form: any;
      table: IDataWarehouse[];
      file?: ResponseUploadData;
    }): RequestWarehouseTransfer {
      const req: RequestWarehouseTransfer = {
        attachment: data.file?.url || "",
        date: data.form?.date.utc().format() || "",
        destinationLocationId: data.form?.to || "",
        notes: data.form?.note || "",
        sourceLocationId: data.form?.from || "",
        transferLines: [],
        deletedLineIds: this.deletedLineIds,
      };
      if (data.table.length) {
        data.table.forEach(x => {
          req.transferLines.push({
            id: x.id,
            productId: x.productId,
            totalQty: x.qtyTotal,
            uomId: x.uomId,
          });
        });
      }
      return req;
    },
    async createWarehouseTransfer(): Promise<void> {
      try {
        const req = this.prepReq({
          form: this.form.getFieldsValue(),
          table: this.dataList,
          file: this.resFile,
        });
        this.loading.submit = true;
        await this.submitTransfer("-", req);
      } catch (error) {
        this.$message.error(Messages.CREATE_FAIL);
      } finally {
        this.loading.submit = false;
      }
    },
    async saveAsDraft(): Promise<void> {
      const formValue = this.form.getFieldsValue();
      const tableValue = this.dataList;
      this.form.validateFields(err => {
        const indx = tableValue.findIndex(x => !x.productId || !x.qtyTotal);
        if ((err && err.length) || indx !== -1) return;
        const req: RequestWarehouseTransfer = {
          attachment: this.urlAttachment,
          date: this.moment(formValue.date).format() || "",
          destinationLocationId: formValue.to || "",
          notes: formValue.note || "",
          sourceLocationId: formValue.from || "",
          transferLines: tableValue.map(item => ({
            id: item.id,
            productId: item.productId,
            receivedQty: item.qtyReceive,
            totalQty: item.qtyTotal,
            uomId: item.uomId,
            secureId: item.id || null,
          })),
          deletedLineIds: this.deletedLineIds,
        };
        if (formValue.receivedDate) {
          req.receivedDate = formValue.receivedDate;
        }
        if (this.documentId) {
          this.updateWarehouseTransfer(req);
        } else {
          this.createDraftWarehouseTransfer(req);
        }
      });
    },
    async updateWarehouseTransfer(
      req: RequestWarehouseTransfer
    ): Promise<void> {
      try {
        this.loading.draft = true;
        const response = await logisticServices.updateWarehouseTransfer(
          this.documentId,
          req
        );
        this.showNotifSuccess("notif_update_success", {
          documentNumber: response.documentNumber,
        });
        this.$router.push(`./warehousetransferlist`);
      } catch (error) {
        this.showNotifError("notif_update_fail");
      } finally {
        this.loading.draft = false;
      }
    },
    async createDraftWarehouseTransfer(
      req: RequestWarehouseTransfer
    ): Promise<void> {
      try {
        this.loading.draft = true;
        const res = await logisticServices.createWarehouseTransfer(req);
        this.documentId = res.id;
        this.showNotifSuccess("notif_document_created_as_draft_success", {
          documentNumber: res.documentNumber,
        });
        this.form.resetFields();
        this.$router.push(`./warehousetransferlist`);
      } catch (error) {
        this.showNotifError("notif_create_fail");
      } finally {
        this.loading.draft = false;
      }
    },
    async getDetail(docId: string): Promise<void> {
      try {
        const res = await logisticServices.detailWarehouseTransfer(docId);
        this.detailTransfer = res;
        if (this.detailTransfer.state === WAREHOUSE_STATE.SUBMITTED) {
          this.handleTransferValidation();
        }
        this.populateForm();
      } catch (error) {
        this.$message.error(Messages.PROCESS_FAIL);
      }
    },
    async updateTransfer(
      docId: string,
      payload: RequestWarehouseTransfer
    ): Promise<DataListWarehouseTransfer> {
      return logisticServices.updateWarehouseTransfer(docId, payload);
    },
    async submitTransfer(
      docId: string,
      payload: RequestWarehouseTransfer
    ): Promise<void> {
      try {
        this.loading.submit = true;
        const res = await logisticServices.submitWarehouseTransfer(
          docId,
          payload
        );
        this.documentId = res.id;
        this.form.resetFields();
        this.showNotifSuccess("notif_document_created_as_submit_success", {
          documentNumber: res.documentNumber,
        });
        this.$router.push(`./warehousetransferlist`);
      } catch (error) {
        this.showNotifError("notif_process_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    async receiveTransfer(): Promise<void> {
      try {
        const payload: RequestWarehouseTransfer = {
          attachment: this.detailTransfer.attachment || "",
          date: this.detailTransfer.date || "",
          destinationLocationId: this.detailTransfer.destinationLocationId,
          notes: this.detailTransfer.notes || "",
          sourceLocationId: this.detailTransfer.sourceLocationId,
          receivedDate:
            this.form.getFieldValue("receiveDate")?.utc().format() || "",
          transferLines: [],
          deletedLineIds: this.deletedLineIds,
        };
        if (this.detailTransfer.transferLines.length) {
          this.detailTransfer.transferLines.forEach(item => {
            const findColumn = this.columnsTable.find(
              column => column.responsiveColSelect
            )?.responsiveColSelect;
            if (findColumn) {
              const checkUom = findColumn[0].options.some(
                dataSome => dataSome.id === item.uom.id
              );
              const checkProduct = findColumn[1].options.some(
                dataSome => dataSome.id === item.product.id
              );
              if (!checkUom) {
                findColumn[0].options.push({
                  name: item.uom.unit,
                  id: item.uom.id,
                  unit: item.uom.unit,
                  description: item.uom.description,
                  uomId: item.uom.id,
                });
              }
              if (!checkProduct) {
                findColumn[1].options.push({
                  id: item.product.id,
                  name: item.product.name,
                  uomId: item.product.uomId,
                });
              }
            }
            payload.transferLines.push({
              id: item.id || "",
              productId: item.product.id || "",
              totalQty: item.qty || 0,
              uomId: item.uom.id || "",
              secureId: item.id || "",
            });
          });
        }
        if (this.dataList.length) {
          this.dataList.forEach((item, index) => {
            payload.transferLines[index].receivedQty = item.qtyReceive;
          });
        }
        this.loading.submit = true;
        const res = await logisticServices.receiveWarehouseTransfer(
          this.documentId,
          payload
        );
        this.form.resetFields();
        this.$message.success(
          `Document ${res.documentNumber} has been received`,
          15
        );
        this.$router.push(`/logistic/warehousetransferlist`);
      } catch (error) {
        this.$message.error(Messages.PROCESS_FAIL);
      } finally {
        this.loading.submit = false;
      }
    },
    backForm() {
      this.$confirm({
        title: this.$t("lbl_leave_page"),
        onOk: () => {
          this.$router.push(`/logistic/warehousetransferlist`);
        },
        onCancel() {
          return;
        },
      });
    },
    resetForm() {
      if (!this.documentId) {
        this.form.resetFields();
        this.dataList = [];
      }
    },
    async cancelForm(): Promise<void> {
      this.$confirm({
        title: Messages.CONFIRM_CANCEL_TITLE,
        content: `Are you sure want to cancel ${this.form.getFieldValue(
          "no"
        )} ?`,
        onOk: async () => {
          await logisticServices.cancelWarehouseTransfer(this.documentId);
          this.$notification.success({
            description: Messages.UPDATE_SUCCESS,
            message: "Success",
            duration: 30,
          });

          this.$router.push("/logistic/warehousetransferlist");
        },
        onCancel() {
          return;
        },
      });
    },
    getListOfProduct(): void {
      const param: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };

      if (this.locationId.from) {
        param.search = `warehouseLocation.secureId~` + this.locationId.from;
      }

      logisticServices
        .listOfInventory(param)
        .then((res: ResponseListOfInventory) => {
          const findColumn = this.columnsTable.find(
            column => column.responsiveColSelect
          )?.responsiveColSelect;
          if (findColumn) {
            findColumn[1].options = res.data
              .map(e => {
                return {
                  id: e.product.id,
                  name: e.product.name,
                };
              })
              .filter(
                (value, index, self) =>
                  index === self.findIndex(t => t.id === value.id)
              );
          }
          res.data.forEach((dataObject, index) => {
            dataObject.key = index;
            dataObject.productId = dataObject.product.id;
            dataObject.name = dataObject.product.name;
          });
          this.dataListInventory = res.data;
        });
    },
    handleChangeLocationFrom(value) {
      this.locationId.from = value;
      this.getListOfProduct();
    },
    handleSearchSelectTable(value): void {
      let params = {
        limit: 10,
      } as RequestQueryParamsModel;
      if (value) {
        params.search = `product.name~*${value}*_OR_product.code~*${value}*_AND_warehouseLocation.secureId~${this.locationId.from}`;
      }
      if (!value) {
        params.page = 0;
      }
      const findColumn = this.columnsTable.find(
        column => column.responsiveColSelect
      )?.responsiveColSelect;
      logisticServices.listOfInventory(params).then(data => {
        if (findColumn) {
          findColumn[1].options = data.data.map(e => {
            return {
              id: e.product.id,
              name: e.product.name,
            };
          });
        }
      });
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current > moment().endOf("day");
    },
    assignOptionProduct() {
      const findColumn = this.columnsTable.find(
        column => column.responsiveColSelect
      )?.responsiveColSelect;
      if (
        this.detailTransfer &&
        Object.keys(this.detailTransfer).length > 1 &&
        this.dataListInventory.length > 0
      )
        if (findColumn) {
          findColumn[1].options = this.detailTransfer.transferLines.map(
            (dataMap, index) => {
              return { id: dataMap.product.id, name: dataMap.product.name };
            }
          );
          this.dataListInventory.forEach(e => {
            findColumn[1].options.push({
              id: e.product.id,
              name: e.product.name,
            } as any);
          });
          findColumn[1].options = findColumn[1].options.filter(
            (value, index, self) =>
              index === self.findIndex(t => t.id === value.id)
          );
        }
    },
    initForm(): void {
      const values = {
        no: undefined,
        from: undefined,
        to: undefined,
        date: moment(),
        receiveDate: null,
        note: "",
        attachment: [],
      };
      for (const key in values) {
        this.form.getFieldDecorator(key, {
          initialValue: values[key],
        });
      }
      this.form.setFieldsValue(values);
    },
    disableOpt(id: string, source: "to" | "from"): boolean {
      return this.form.getFieldValue(source) === id;
    },
  },
});
